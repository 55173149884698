import { CacheProvider, EmotionCache } from '@emotion/react';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import nextI18nextConfig from '~/next-i18next.config';
import { Providers } from '~/providers';
import createEmotionCache from '~/utils/createEmotionCache';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

function CustomApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: MyAppProps) {
  const { i18n } = useTranslation();

  return (
    <CacheProvider value={emotionCache}>
      <Script
        async
        data-language={i18n.language}
        id="ozysu2xCT"
        src="https://app.usercentrics.eu/latest/main.js"
        type="application/javascript"
      />
      <Script
        strategy="afterInteractive"
        id="gtm-script"
        type="text/plain"
        data-usercentrics="Google Tag Manager"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WSLPZRL');`,
        }}
      />
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.webmanifest" />
      </Head>
      <DefaultSeo
        title="GRIMME Gruppe - Kartoffel-, Rüben- und Gemüsetechnik"
        description="In der Gemüsetechnik bauen sowohl GRIMME als auch das Tochterunternehmen ASA-LIFT eine Vielzahl innovativer Maschinen rund um die Ernte von Zwiebeln, ..."
      />
      <Providers pageProps={pageProps}>
        <Component {...pageProps} />
      </Providers>
    </CacheProvider>
  );
}

export default appWithTranslation(CustomApp, nextI18nextConfig);
