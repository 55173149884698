import {
  ComponentsConfigProvider,
  GrimmeComponentsProvider,
  withNotificationsReducer,
} from '@grimme/components';
import { AnalyticsProvider, useUserCentricsConsent } from '@grimme/gdap';
import { useSession } from '@grimme/next-grimme-auth';
import theme from '@grimme/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/nextjs';
import { AppProps } from 'next/app';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { environment } from '~/environment';

const store = configureStore({
  reducer: withNotificationsReducer({}),
});

interface ProviderProps {
  children?: React.ReactElement[] | React.ReactElement;
  pageProps: AppProps['pageProps'];
}

export function Providers({ children, pageProps }: ProviderProps) {
  const { commitHash, insightsKey, projectName, appVersion } = environment;
  const { t, i18n } = useTranslation();

  const [queryClient] = useState(() => new QueryClient());

  const { data } = useSession();
  const isAiConsentGiven = useUserCentricsConsent('Azure Application Insights');
  const isSentryConsentGiven = useUserCentricsConsent('Sentry');

  useEffect(() => {
    Sentry.getCurrentHub().getClient().getOptions().enabled =
      isSentryConsentGiven;
  }, [isSentryConsentGiven]);

  // TODO: Remove as soon as the theme is updated with the new styles.
  const websiteTheme = theme;
  websiteTheme.palette.background.default = theme.palette.grey[100];

  return (
    <ComponentsConfigProvider t={t} language={i18n.language}>
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <ThemeProvider theme={websiteTheme}>
              <CssBaseline />
              <AnalyticsProvider
                instrumentationKey={insightsKey}
                isConsentGiven={isAiConsentGiven}
                defaultEnvelopeData={{
                  commit: commitHash,
                  project: projectName,
                  version: appVersion,
                }}
                username={data?.user?.email}
              >
                <GrimmeComponentsProvider>{children}</GrimmeComponentsProvider>
              </AnalyticsProvider>
            </ThemeProvider>
          </Hydrate>
        </QueryClientProvider>
      </ReduxProvider>
    </ComponentsConfigProvider>
  );
}
